import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import TopLogo from './TopLogo';
import MetaTagsComponent from './MetaTagsComponent';

const Box = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  pressable: true,
  press: { scale: 0.8 },
  init: { scale: 1 },
  hoverable: true,
  hover: { scale: 1.2 }
});

const Title = posed.h1({
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
});

class Games extends Component {
  state = { umbrellagirl: false };

  componentDidMount() {
    this.titleTimer = setTimeout(() => {
      this.setState({ title: true });
    }, 600);
    this.umbrellagirlTimer = setTimeout(() => {
      this.setState({ umbrellagirl: true });
    }, 1200);
    this.tapocketaTimer = setTimeout(() => {
      this.setState({ tapocketa: true });
    }, 1400);
    this.mauaTimer = setTimeout(() => {
      this.setState({ maua: true });
    }, 1400);
    this.goBackTimer = setTimeout(() => {
      this.setState({ goBack: true });
    }, 1600);
    window.scrollTo(0, 0);
  }

  render() {
    const { title } = this.state;
    const { umbrellagirl } = this.state;
    const { tapocketa } = this.state;
    const { maua } = this.state;
    const { goBack } = this.state;

    return (
      <section className="games">
        <MetaTagsComponent />

        <TopLogo />
        <Title className="box" pose={title ? 'visible' : 'hidden'}>
          Our Games
                </Title>

        <div className="main-menu">
          <ul>
            <Box className="box" pose={umbrellagirl ? 'visible' : 'hidden'}>
              <li><Link to="/umbrellagirl" onClick={() => this.setState({ maua: !maua, tapocketa: !tapocketa, goBack: !goBack })}>
                <h2>Umbrellagirl</h2>
              </Link></li>
            </Box>
            <Box className="box" pose={tapocketa ? 'visible' : 'hidden'}>
              <li><Link to="/tapocketa" onClick={() => this.setState({ maua: !maua, umbrellagirl: !umbrellagirl, goBack: !goBack })}>
                <h2>Tapocketa</h2>
              </Link></li>
            </Box>
            <Box className="box" pose={maua ? 'visible' : 'hidden'}>
              <li><Link to="/maua" onClick={() => this.setState({ umbrellagirl: !umbrellagirl, tapocketa: !tapocketa, goBack: !goBack })}>
                <h2>Maua</h2>
              </Link></li>
            </Box>
            <Box className="box" pose={goBack ? 'visible' : 'hidden'}>
              <li><Link to="/" onClick={() => this.setState({ umbrellagirl: !umbrellagirl, tapocketa: !tapocketa })}>
                <div className="go-back-button">
                  <h3>&lt; Go back</h3>
                </div>
              </Link></li>
            </Box>
          </ul>
        </div>
      </section>
    );
  }
}

export default Games;