import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import TopLogo from './TopLogo';
import MetaTagsComponent from './MetaTagsComponent';

const Container = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    enter: { staggerChildren: 300 }
});

const Box = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 }
});

const Button = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 },
    pressable: true,
    press: { scale: 0.8 },
    init: { scale: 1 },
    hoverable: true,
    hover: { scale: 1.2 }
});

class Tapocketa extends Component {
    state = { isVisible: false };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ isVisible: true });
        }, 600);
    }

    render() {
        const { isVisible } = this.state;

        return (
            <section className="tapocketa">
                <MetaTagsComponent />

                <TopLogo />
                <Container className="box" pose={isVisible ? 'visible' : 'hidden'}>
                    <Box>
                        <h1>Tapocketa</h1>
                    </Box>
                    <Box className="quote">
                        <h4>
                            “See that building over yonder, kid?<br />
                            That’s where they used to produce them robots.<br />
                            Before it all went to hell.”

                </h4>
                    </Box>
                    <Box>
                        <p>
                        The development of this <b>robot programming puzzle game</b> has
              unfortunately been put on ice.
            </p>
                    </Box>
                    <ul>
                        <li><Link to="/games">
                            <Button className="go-back-button">
                                <h3>&lt; Go back</h3>
                            </Button>
                        </Link></li>
                    </ul>

                </Container>
            </section>
        );
    }
}

export default Tapocketa;