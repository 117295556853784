import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Homepage from './Homepage';
import Contact from './Contact';
import AboutUs from './AboutUs';
import Games from './Games';
import UnderConstruction from './UnderConstruction';
import Umbrellagirl from './Umbrellagirl';
import Tapocketa from './Tapocketa';
import Maua from './Maua';
import posed, { PoseGroup } from 'react-pose';
import Privacy from './Privacy';

const RoutesContainer = posed.div({
    enter: { opacity: 1, delay: 200 },
    exit: { opacity: 0, delay: 300 }
});


const AppRouter = (props) => (
    <Route render={ ({ location }) => (
        <PoseGroup>
            <RoutesContainer key={location.pathname}>
                <Switch location={location}>
                        {/* <Route path="/" exact component={UnderConstruction} /> */}
                        <Route path="/" exact component={Homepage} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/about" component={AboutUs} />
                        <Route path="/games" component={Games} />
                        <Route path="/umbrellagirl" component={Umbrellagirl} />
                        <Route path="/tapocketa" component={Tapocketa} />
                        <Route path="/maua" component={Maua} />
                        <Route path="/privacy" component={Privacy} />
                        <Redirect from='*' to='/' />
                        </Switch>
            </RoutesContainer>
        </PoseGroup>
    )}/>
)

export default AppRouter;