import React, { Component } from 'react';
import './../stylesheets/general.css';

class Footer extends Component {
    render() {
        return (
            <section>
                <div className="social-icons">
                    <a href="https://www.instagram.com/paraflux_games/" target="_blank" rel="noopener noreferrer">
                        <img className="icon" src={require('./../pics/icons/insta-icon.png')} alt="Instagram" />
                    </a>
                    <a href="https://www.facebook.com/parafluxgames/" target="_blank" rel="noopener noreferrer">
                        <img className="icon" src={require('./../pics/icons/fb-icon.png')} alt="Facebook" />
                    </a>
                </div>
            </section>
        );
    }
}

export default Footer;