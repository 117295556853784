import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import MainLogo from './MainLogo';
import posed from 'react-pose';
import MetaTagsComponent from './MetaTagsComponent';

const Box = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    pressable: true,
    press: { scale: 0.8 },
    init: { scale: 1 },
    hoverable: true,
    hover: { scale: 1.2 }
});

class Homepage extends Component {
    state = { games: false };

    componentDidMount() {
        this.gamesTimer = setTimeout(() => {
            this.setState({ games: true });
        }, 1200);
        this.aboutUsTimer = setTimeout(() => {
            this.setState({ aboutUs: true });
        }, 1400);
        this.contactTimer = setTimeout(() => {
            this.setState({ contact: true });
        }, 1600);
        window.scrollTo(0, 0);
    }

    render() {
        const { games } = this.state;
        const { aboutUs } = this.state;
        const { contact } = this.state;

        return (
            <section className="homepage">
                <MetaTagsComponent />
                <MainLogo />

                <div className="main-menu">
                    <ul>
                        <Box className="box" pose={games ? 'visible' : 'hidden'}>
                            <li><Link to="/games" onClick={() => this.setState({ aboutUs: !aboutUs, contact: !contact })}>
                                <h1>Our Games</h1>
                            </Link></li>
                        </Box>
                        <Box className="box" pose={aboutUs ? 'visible' : 'hidden'}>
                            <li><Link to="/about" onClick={() => this.setState({ games: !games, contact: !contact })}>
                                <h1>About Us</h1>
                            </Link></li>
                        </Box>
                        <Box className="box" pose={contact ? 'visible' : 'hidden'}>
                            <li><Link to="/contact" onClick={() => this.setState({ aboutUs: !aboutUs, games: !games })}>
                                <h1>Contact</h1>
                            </Link></li>
                        </Box>
                    </ul>
                </div>

                <div className="title">
                    <h2>Paraflux Games</h2>
                </div>

                <Footer />
            </section>
        );
    }
}

export default Homepage;