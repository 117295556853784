import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';

class TopLogo extends Component {
    render() {
        return (
            <section>
                <div className="top-logo">
                <Link to="/homepage">
                    <img src={require('./../pics/paraflux-logo-circle.png')} alt="Paraflux logo" />
                </Link>
                </div>
            </section>
        );
    }
}

export default TopLogo;