import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import TopLogo from './TopLogo';
import MetaTagsComponent from './MetaTagsComponent';

const Container = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    enter: { staggerChildren: 400 },
});

const Box = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 }
});

const Button = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 },
    pressable: true,
    press: { scale: 0.8 },
    init: { scale: 1 },
    hoverable: true,
    hover: { scale: 1.2 }
});

class AboutUs extends Component {
    state = { isVisible: false };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ isVisible: true });
        }, 500);
    }

    render() {
        const { isVisible } = this.state;

        return (
            <section className="about-us">
                <MetaTagsComponent />
                <TopLogo />
                <Container className="box" pose={isVisible ? 'visible' : 'hidden'}>
                    <Box>
                        <h1>About us</h1>
                    </Box>
                    <Box>
                        <h4>
                            We strive to create games that are <b>easy to pick up</b>, but <b>hard to master</b>.
                        </h4>
                    </Box>
                    <Box>
                        <h2>Our Mission</h2>
                        <p className="quote">“Easy to pick up, hard to master”</p>
                        <p className="description">In essence, these words encapsulate what we think a <b>good game</b> should be.
                    </p>
                    <p>And good games are what we strive to create.</p><br />
                    </Box>
                    <Box>
                    <h2>Our History</h2>
                    <p>
                        In 2015, the idea of building a company around creating games started brewing..<br />
                        and three years later, our first game was released for iOS and android - <b>Umbrellagirl</b>.<br />
                      </p>

                      <p>The idea still lingers, and today we are a small group of dedicated creators based in Stockholm, Sweden.</p><br />
                    </Box>

                    <ul>
                        <li><Link to="/">
                            <Button className="go-back-button about-us-go-back">
                                <h3>&lt; Go back</h3>
                            </Button>
                        </Link>
                        </li>
                    </ul>


                </Container>
            </section>
        );

    }
}

export default AboutUs;