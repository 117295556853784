import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import posed from 'react-pose';

const Box = posed.p({
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
});

class MainLogo extends Component {
    state = { isVisible: false };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ isVisible: true });
        }, 500);
    }

    componentWillUnmount() {
    }

    render() {
        const { isVisible } = this.state;

        return (
            <section>
                <Box className="box" pose={isVisible ? 'visible' : 'hidden'}>
                    <Link to="/">
                        <img className="main-logo swing" src={require('./../pics/paraflux-logo.png')} alt="Paraflux logo" />
                    </Link>
                </Box>
            </section>
        );
    }
}

export default MainLogo;