import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import TopLogo from './TopLogo';
import MetaTagsComponent from './MetaTagsComponent';

const Container = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    enter: { staggerChildren: 300 }
});

const Box = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 }
});

const Button = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 },
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 },
    hoverable: true,
    hover: { scale: 1.2 }
});


class Umbrellagirl extends Component {
    state = { isVisible: false };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ isVisible: true });
        }, 600);
    }

    render() {
        const { isVisible } = this.state;

        return (
            <section className="umbrellagirl">
                <MetaTagsComponent />
                <TopLogo/>
                <Container className="box" pose={isVisible ? 'visible' : 'hidden'}>
                <Box>
                    <h1>Umbrellagirl</h1>
                </Box>
                <Box>
                <h4>
                    Get blown away by the wonderful world of Umbrellagirl!
                </h4>
                </Box>
                <Box>
                <p>
                    Experience the beautiful world of Umbrellagirl where she step by step learns how to use her abilities to reach new places. Start from a swing and use the tools at hand to master your abilities and reach the magical castle at the end of the horizon.
                    
                    </p>
                    </Box>
                    <Box>
                    <p>
                        Discover hungry flowers, uplifting fans and find out how an umbrella can be used in more ways than protection from rain in this relaxed, challenging and adventurous platform game.
                </p>
                </Box>
                <Box className="appstore">
                <ul>
                    <li>
                    Try it out on iOS today! <span> -></span>
                    <a href="https://apps.apple.com/se/app/umbrellagirl/id1049952793" target="_blank" rel="noopener noreferrer">
                    <img className="icon" src={require('./../pics/icons/appstore-icon.png')} alt="Appstore" /> </a>
                        
                    </li>
                </ul>
                </Box>

                
                    <Link to="/games">
                    <Button className="go-back-button">
                                    <h3>&lt; Go back</h3>
                                </Button>
                    </Link>
                

                </Container>
            </section>
        );
    }
}

export default Umbrellagirl;