import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class MetaTagsComponent extends Component {

    render() {

        return (
            <Helmet>
                <title>Paraflux Games - A Stockholm based indie game studio</title>
                <meta property="og:type" content=" website" />
                <meta property="og:title" content="Paraflux Games - A Stockholm based indie game studio" />
                <meta property="og:image" content={"https://www.paraflux-games.com" + require("./../pics/paraflux_games-preview.png")} />
                <meta property="og:image:secure_url" content={"https://www.paraflux-games.com" + require("./../pics/paraflux_games-preview.png")} />
                <meta property="og:description" content="Paraflux Games - We create games that are easy to pick up, but hard to master." />
                <meta property="og:url" content="https://www.paraflux-games.com" />
                <meta property="og:type" content=" website" />
                <meta property="fb:app_id" content="631121574064665" />
            </Helmet>
        );
    }
}

export default MetaTagsComponent;
