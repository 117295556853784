import React, { Component } from 'react';
import './../stylesheets/general.css';
import { Link } from 'react-router-dom';
import posed from 'react-pose';
import TopLogo from './TopLogo';
import MetaTagsComponent from './MetaTagsComponent';

const Container = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    enter: { staggerChildren: 200 }
});

const ListContainer = posed.ul({
    enter: { staggerChildren: 50 },
    exit: { staggerChildren: 20, staggerDirection: -1 }
});

const Item = posed.li({
    enter: { y: 0, opacity: 1 },
    exit: { y: 0, opacity: 0 }
});

const Box = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 }
});

const Button = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 0 },
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 },
    hoverable: true,
    hover: { scale: 1.2 }
});

class Contact extends Component {
    state = { isVisible: false };

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ isVisible: true });
        }, 500);
    }

    render() {
        const { isVisible } = this.state;

        return (
            <section className="contact">
                <MetaTagsComponent />
                <TopLogo/>


                <Container className="box" pose={isVisible ? 'visible' : 'hidden'}>
                    <Box>
                        <h1>Contact</h1>
                    </Box>
                    <Box>
                        <h4>
                        Do you want to collaborate? Or 
                        simply want to get in touch?
                    </h4>
                    </Box>

                    <Box>
                        <p>
                        Don’t hesitate to reach out via <b>email</b> or on <b>social media!</b>
                </p>
                    </Box>

                    <div className="list-container">
                        <ListContainer>

                            <Item>

                                <a href="https://www.instagram.com/paraflux_games/" target="_blank" rel="noopener noreferrer">
                                    <img className="icon" src={require('./../pics/icons/insta-icon.png')} alt="Instagram" /> paraflux_games
                        </a>
                            </Item>

                            <Item><a href="https://www.facebook.com/parafluxgames/" target="_blank" rel="noopener noreferrer">
                                <img className="icon" src={require('./../pics/icons/fb-icon.png')} alt="Facebook" /> parafluxgames
                        </a>
                            </Item>
                            <Item><a href="mailto:info@paraflux.games">
                                <img className="icon" src={require('./../pics/icons/email-icon-white.png')} alt="Email" /> info@paraflux.games
                        </a>
                            </Item>
                        </ListContainer>
                    </div>


                    <ul>
                        <li><Link to="/">
                            <Button className="go-back-button">
                                <h3>&lt; Go back</h3>
                            </Button>
                        </Link></li>
                    </ul>

                </Container>
            </section>
        );
    }
}

export default Contact;